declare global {
  interface Window {
    appzi?: {
      openSurvey: (surveyId: string) => void;
    };
  }
}

export function useAppzi() {
  function openNpsModal() {
    if (window?.appzi) {
      window?.appzi.openSurvey('23bac41a-43f1-44e1-9f26-ca963ec98483');
    }
  }

  return { openNpsModal };
}
